import React, {useState} from "react";
import Modal from "react-modal";
import Cookies from "js-cookie";
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import {FaRegCopy} from "react-icons/fa";
import copy from 'copy-to-clipboard';

const ShareModal = ({
                        isOpen,
                        onRequestClose,
                        url = "www.cricket8.com",
                        sharename = "Cricket8",
                        title = "Check this out - Grab free coins before the launch of exciting new games! Join the Cricket8 community and start collecting rewards today!",
                        hashtags = ["cricket"]
                    }) => {
    const [copied, setCopied] = useState(false);
    url = window.location.origin + "/match";

    const handleCopy = () => {
        copy(title + " " + url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
        handleShare("Copy To Clipboard");
    };

    const handleShare = (platform) => {
        if (sharename === "GAME PLAY") {
            addSharePoints(platform);
        }
    };

    const addSharePoints = async (platform) => {
        const session_token = Cookies.get("c8_session_token");
        if (session_token) {
            await fetch(
                `${process.env.API_URL}/sharepoints/${platform}/${session_token}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.API_KEY,
                    },
                }
            );
        }
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Share Modal"
                ariaHideApp={false}
                className="modal"
                overlayClassName="overlay"
            >
                <div>
                    <h2>SHARE</h2>
                    <div className="share-buttons">
                        <FacebookShareButton
                            hashtag={"#" + hashtags.join(" #")}
                            title={title}
                            url={url}
                            quote={
                                "Check this out - Grab your free coins before the launch of our exciting new game! Join the Cricket8.com community today and start collecting your rewards!"
                            }
                            onClick={() => handleShare("Facebook")}
                        >
                            <FacebookIcon size={32} square/>
                        </FacebookShareButton>
                        <WhatsappShareButton
                            url={url}
                            title={title}
                            hashtag={hashtags}
                            onClick={() => handleShare("Whatsapp")}
                        >
                            <WhatsappIcon size={32} square/>
                        </WhatsappShareButton>
                        <TelegramShareButton
                            url={url}
                            onClick={() => handleShare("Telegram")}
                            title={title}>
                            <TelegramIcon size={32} square/>
                        </TelegramShareButton>
                        <FaRegCopy size={32} onClick={() => handleCopy()}></FaRegCopy>
                    </div>
                    {copied ?
                        <span style={{
                            fontSize: '7px',
                            marginTop: "5px",
                            marginRight: "5px",
                            display: "block",
                            justifySelf: "right"
                        }}>Copied to clipboard</span> : null}
                </div>
            </Modal>
        </div>
    );
};

export default ShareModal;
